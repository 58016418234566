<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            default-href="/tabs/help/emergency-numbers"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.emergencyNumberDetails.title') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="odn-emergency-number">
        <div class="odn-emergency-number-name">
          {{ emergencyNumber.name }}
        </div>
        <div v-if="emergencyNumber.phone" class="odn-emergency-number-phone">
          <ion-icon :icon="callOutline" class="ion-margin-end" />
          <a :href="`callto:${emergencyNumber.phone}`">
            {{ emergencyNumber.phone }}
          </a>
        </div>
        <div v-if="emergencyNumber.email" class="odn-emergency-number-email">
          <ion-icon :icon="mailOutline" class="ion-margin-end" />
          <a :href="`mailto:${emergencyNumber.email}`">
            {{ emergencyNumber.email }}
          </a>
        </div>
        <div
          v-html="emergencyNumber.notes"
          class="odn-emergency-number-notes"
        ></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonIcon,
  toastController,
} from '@ionic/vue';
import { mailOutline, callOutline } from 'ionicons/icons';

import APIService from '@s/api.service';

export default {
  name: 'EmergencyNumberDetails',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonIcon,
  },
  data() {
    return {
      emergencyNumber: {},
      loading: true,
      mailOutline,
      callOutline,
    };
  },
  ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.loading = true;
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const response = await APIService.get(
          `/emergency-numbers/${this.$route.params.id}`
        );
        this.emergencyNumber = response.data;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.emergencyNumber.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.odn-emergency-number {
  padding: 16px;

  &-name {
    font-size: 20px;
  }

  &-email,
  &-phone {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    font-size: 16px;

    > ion-icon {
      font-size: 30px;
    }

    > a {
      display: inline-block;
      color: var(--ion-color-secondary);
      text-decoration: none;
    }
  }

  &-notes {
    margin-top: 16px;
  }
}
</style>
